<template>
  <div class="resetpassword-container">
    <div class="header">
      <div class="title">
        Password Reset
      </div>
    </div>

    <div v-if="token" class="reset-password">
      <template v-if="passwordSuccessfullyChanged == false">
        <div class="label">
          Email
        </div>
        <div class="element" style="margin-bottom: 20px">
          <styled-input v-model="email" :placeholder="'your@email.com'" :validFn="emailValidFn"/>
        </div>
        <div class="label">
          New Password
        </div>
        <div class="element" style="margin-bottom: 20px">
          <styled-input v-model="password" :type="'password'" :validFn="v => v.length > 5"/>
        </div>
        <div class="label">
          Confirm Password
        </div>
        <div class="element" style="margin-bottom: 20px">
          <styled-input v-model="passwordConfirm" :type="'password'" :validFn="v => v == password"/>
        </div>
        
        <div class="submit">
          <div class="button" :class="{ disabled: !dataValid }" @click="resetPassword()">Save New Password</div>
        </div>
      </template>
      <template v-else>
        <div class="success">
          Password successfully changed!
          <div class="button" @click="$router.push('/login')">Proceed to Login</div>
        </div>
      </template>
    </div>

    <div v-else class="request-token">
      <template v-if="tokenRequested == false">
        <div class="label">
          Request new Password
        </div>
        <div class="element" style="margin-bottom: 20px">
          <styled-input v-model="email" :placeholder="'your@email.com'" :validFn="emailValidFn"/>
        </div>
        
        <div class="submit">
          <div class="button" :class="{ disabled: !emailValidFn(email) }" @click="requestToken()">
            Request new Password
          </div>
        </div>
      </template>
      <template v-else>
        We sent you an email with further instructions on how to reset your password.
      </template>
    </div>

  </div>
</template>

<script>
import authService from '@/services/auth-service.js'

import StyledInput from '@/components/utility/StyledInput.vue'

export default {
  name: 'ResetPassword',
  components: {
    StyledInput,
  },
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',

      tokenRequested: false,
      passwordSuccessfullyChanged: false,
    }
  },
  methods: {
    emailValidFn(username) {
      const re = /\S+@\S+\.\S+/
      return re.test(username)
    },
    async requestToken() {
      if(!this.emailValidFn(this.email)) {
        return
      }
      this.$loading(true)
      try {
        await authService.requestPasswordReset(this.email)
        this.tokenRequested = true
      } finally {
        this.$loading(false)
      }
    },
    async resetPassword() {
      if(!this.dataValid) {
        return
      }
      this.$loading(true)
      await authService.saveNewPassword(this.email, this.password, this.token)
      this.$loading(false)
      this.passwordSuccessfullyChanged = true
    }
  },
  computed: {
    dataValid() {
      return this.emailValidFn(this.email) && this.password.length > 5 && this.passwordConfirm == this.password
    },
    token() {
      return this.$route.query.token
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.resetpassword-container {
  margin: auto
}

.header {
  color: white;
}

.title {
  font-size: 46px;
  font-weight: bold;
  margin: 10px 0px;
}


.reset-password, .request-token {
  margin-top: 50px;
  background-color: white;
  border-radius: 6px;
  padding: 30px;
  text-align: left;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);

  .label {
    color: $primary;
    padding-left: 5px;
    // font-size: 14px;
  }
  
  .element {
    position: relative;
    input {
      width: 100%;
      box-sizing: border-box;
      background-color: #ebf0f2;
      border-radius: 6px;
      color: $dark;
      outline: none;
      border: none;
      padding: 10px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 500;
      
      &:focus {
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.submit {
  text-align: center;
  margin-top: 50px;
}

.success {
  text-align: center;

  .button {
    margin-top: 30px;
  }
}

@media screen and (min-width: 600px){
  .resetpassword-container {
    width: 500px;
  }
}
</style>
