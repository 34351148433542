<template>
  <div class="passwordresetview-container gradient">

    <div style="padding-top: 50px">
      <reset-password/>
    </div>

  </div>
</template>

<script>
import ResetPassword from '@/components/ResetPassword.vue'

export default {
  name: 'ResetPasswordView',
  components: {
    ResetPassword,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.passwordresetview-container {
  background-color: $primary;
  min-height: 100vh;
}


@media screen and (max-width: 400px) {
  .loginview-container {
    padding: 50px 30px;
  }
}
</style>
